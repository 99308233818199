<template>
  <div class="template-search-container">
    <div class="template-search-header">
      <div class="back-wrapper flex-align unselect" style="gap: 4px" @click="routerPush('/template_home')">
        <img src="/static/icon/u_arrow-left.svg" style="width: 24px; height: 24px" />
        <div class="subtitle5 main" style="flex: 1">플랫폼 템플릿 홈</div>
      </div>
      <div class="search-box-input">
        <input
          type="text"
          :placeholder="placeholder"
          v-model="inputText"
          autocomplete="off"
          class="search-input"
          name="search-input"
          :style="{ paddingRight: inputText !== '' ? '60px' : '36px' }"
          @keyup.enter="getSearchResult" />
        <img
          v-if="inputText !== ''"
          class="del-button"
          style="width: 16px; height: 16px"
          src="/static/icon/u_close.svg"
          @click="clearSearch" />
        <img
          class="search-button"
          style="width: 20px; height: 20px"
          src="/static/icon/fi_search.svg"
          @click="getSearchResult" />
      </div>
      <div class="subtitle4 main search-result">‘{{ searchResult }}’ 검색결과</div>
    </div>
    <div>
      <TemplateFilterList
        :categories="categories"
        :tempProducts="tempProducts"
        @selectCategory="selectCategory"
        @openProductFilterModal="openProductFilterModal"
        @clickFreeSetFilter="clickFreeSetFilter"
        @resetFilter="resetFilter"
        @removeFiltered="removefilterd"
        @clickScreenshot="clickScreenshot" />
      <!-- 페이지네이션 -->
      <lp-paginate
        v-if="tempProducts.length > 0"
        :filtered="tempFiltered"
        @getData="getData"
        class="margin-top-40"></lp-paginate>
      <!-- <div class="survey-inquiry-container">
        <div class="survey-inquiry-wrapper">
          <div class="item-survey">
            <div>
              <img src="/static/icon/emoticon_hmm.png" style="width:40px;height:40px;margin-bottom: 20px" />
            </div>
            <div class="h6 margin-bottom-20" style="word-break: keep-all">
              내 서비스에 맞는 템플릿이 있는지<br class="pc" />
              찾기 어려우신가요?
            </div>
            <a href="https://walla.my/survey/gZBAG9imMdaiyop6z6It" target="_blank">
              <div class="button-black unselect">템플릿 찾기 도움 요청</div>
            </a>
          </div>
          <div class="item-inquiry">
            <div>
              <img src="/static/icon/ic_inquiry.png" style="width:40px;height:40px;margin-bottom: 20px" />
            </div>
            <div class="h6 margin-bottom-20">찾는 테마가 없으시다면?<br />맞춤 플랫폼 제작!</div>
            <a href="https://launchpack.co.kr/prime" target="_blank">
              <div class="button-sub2 unselect">견적문의하기</div>
            </a>
          </div>
        </div>
      </div> -->
    </div>

    <product-filter-modal ref="productFilterModal" @apply="applyFilter"></product-filter-modal>
    <screenshot-modal ref="screenshotModal" :detail="selectedScreenshot"></screenshot-modal>
  </div>
</template>

<script>
import ProductFilterModal from '../modal/ProductFilterModal';
import TemplateFilterList from '../component/TemplateFilterList.vue';
import LpPaginate from '../component/LpPaginate.vue';
import ScreenshotModal from '../modal/ScreenshotModal';

export default {
  name: 'TemplateSearch',
  components: {
    ProductFilterModal,
    TemplateFilterList,
    LpPaginate,
    ScreenshotModal,
  },
  created() {
    if (this.$store.state.search && this.$store.state.search !== '') {
      this.inputText = this.$store.state.search;
      this.$store.commit('setSearch', null);
      this.getSearchResult();
    } else {
      this.getData();
    }
    this.getTemplateCategory();
  },
  data() {
    return {
      inputText: '',
      searchResult: '',
      categories: [],
      currentImages: {},
      selectedCategory: null,
      freeFilterBtn: false,
      tempProducts: [],
      popularPartner: [],
      tempFiltered: {
        cur_page: 1,
        page_num: 1,
        total_page: 1,
        page_length: this.isMobile ? 16 : 20,
        ordering: '-id',
        category: '59,60,61',
        field:
          'id,name,simple_desc,price,img,partner.img,partner.name,partner.nickname,partner.career,category1,category2,category3,link,preview_url,visit,rate,reviews_count,params,theme_version,tags,techs,is_liked',
      },
      selectedScreenshot: null,
    };
  },
  computed: {
    placeholder() {
      return this.isMobile
        ? '템플릿 제목, 사용된 기술, 파트너명으로 검색'
        : '템플릿 제목, 사용된 기술, 파트너명으로 검색해 보세요.';
    },
  },
  methods: {
    clickScreenshot(card) {
      this.selectedScreenshot = card;
      this.$nextTick(() => {
        this.$refs.screenshotModal.open();
      });
    },
    openProductFilterModal() {
      this.$refs.productFilterModal.open();
    },
    applyFilter(params) {
      if (params && params.length > 0) {
        params.forEach((param) => {
          if (param.start) {
            this.tempFiltered[`${param.key}_start`] = param.start;
            this.tempFiltered[`${param.key}_end`] = param.end;
          } else {
            this.tempFiltered[param.key] = Array.isArray(param.value) ? param.value.join() : param.value;
          }
        });
      } else {
        if (this.tempFiltered.price_start) {
          delete this.tempFiltered[`price_start`];
          delete this.tempFiltered[`price_end`];
        }
        this.tempFiltered.category = '59,60,61';
      }
      let user_id = this.$store.state.user.user_id;
      this.$store.commit('setLoading', true);
      this.$axios.get(`user/${user_id}/mapping/product`, { params: this.tempFiltered }).then((res) => {
        this.tempProducts = res.data.data;
        Object.assign(this.tempFiltered, {
          page_length: res.data.page_length,
          cur_page: Number(res.data.cur_page),
          total_page: res.data.total_page,
        });
        this.$store.commit('setLoading', false);
      });
    },
    resetFilter(item) {
      this.applyFilter(item);
    },
    removefilterd(item) {
      this.applyFilter(item);
    },
    clickFreeSetFilter() {
      this.freeFilterBtn = !this.freeFilterBtn;

      if (this.freeFilterBtn) {
        this.tempFiltered.category = '59,60';
      } else {
        this.tempFiltered.category = 58;
      }
      let user_id = this.$store.state.user.user_id;
      this.$store.commit('setLoading', true);
      this.$axios.get(`user/${user_id}/mapping/product`, { params: this.tempFiltered }).then((res) => {
        this.tempProducts = res.data.data;
        Object.assign(this.tempFiltered, {
          page_length: res.data.page_length,
          cur_page: Number(res.data.cur_page),
          total_page: res.data.total_page,
        });
        this.$store.commit('setLoading', false);
      });
    },
    getTemplateCategory() {
      this.$axios.get('public/template_category').then((categories) => {
        this.categories = categories.data;
        this.categories.unshift({
          id: 0,
          name: '전체',
          img_before: 'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/img/non-path/42387840.png',
          img_after: 'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/img/non-path/37726602.png',
        });
      });
    },
    getSearchResult() {
      if (this.inputText.trim() === '') {
        return; // 검색어가 비어있으면 요청하지 않음
      }

      this.searchResult = this.inputText; // 검색 결과 텍스트 업데이트
      this.$store.commit('setLoading', true);

      const params = {
        ...this.tempFiltered,
        search_keyword: this.inputText,
      };
      let user_id = this.$store.state.user.user_id;
      this.$axios
        .get(`user/${user_id}/mapping/product`, { params })
        .then((res) => {
          this.tempProducts = res.data.data;
          Object.assign(this.tempFiltered, {
            page_length: res.data.page_length,
            cur_page: Number(res.data.cur_page),
            total_page: res.data.total_page,
          });
          this.$store.commit('setLoading', false);
        })
        .catch((error) => {
          console.error('검색 중 오류 발생:', error);
          this.$store.commit('setLoading', false);
        });
    },
    clearSearch() {
      this.inputText = '';
      if (this.searchResult !== '') {
        this.searchResult = '';
        this.tempFiltered = {
          cur_page: 1,
          page_num: 1,
          total_page: 1,
          page_length: this.isMobile ? 16 : 20,
          ordering: '-id',
          category: 58,
        };
        this.getData();
      }
    },
    getData() {
      let user_id = this.$store.state.user.user_id;
      this.$store.commit('setLoading', true);
      this.$axios
        .get(`user/${user_id}/mapping/product`, { params: this.tempFiltered })
        .then((res) => {
          this.tempProducts = res.data.data;
          Object.assign(this.tempFiltered, {
            page_length: res.data.page_length,
            cur_page: Number(res.data.cur_page),
            total_page: res.data.total_page,
          });
          this.$store.commit('setLoading', false);
        })
        .finally(() => {
          // 스크롤을 페이지 상단으로 이동
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        });
    },
    getImageSrc(item) {
      return this.currentImages[item.id] || item.img_before;
    },
    selectCategory(category) {
      this.searchCategory = category;
      this.tempFiltered.template_categories = category ? category.id : null;
      if (category === null) {
        this.inputText = '';
        this.searchResult = '';
        delete this.tempFiltered.template_categories;
        this.tempFiltered.category = '58';
        this.tempFiltered.ordering = '-id';
      } else if (category.id === 0) {
        delete this.tempFiltered.template_categories;
        this.tempFiltered.category = '58';
        this.tempFiltered.ordering = '-id';
      }
      if (this.searchResult !== '') {
        this.getSearchResult();
      } else {
        let user_id = this.$store.state.user.user_id;
        this.$store.commit('setLoading', true);
        this.$axios.get(`user/${user_id}/mapping/product`, { params: this.tempFiltered }).then((res) => {
          this.tempProducts = res.data.data;
          Object.assign(this.tempFiltered, {
            page_length: res.data.page_length,
            cur_page: Number(res.data.cur_page),
            total_page: res.data.total_page,
          });
          this.$store.commit('setLoading', false);
        });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.template-search-container
  padding 48px 0
  margin-top 64px

.template-search-header
  display flex
  flex-direction column
  width 1152px
  margin 0 auto

.search-box-input
  position relative
  display flex
  align-items center
  border-radius 8px
  margin-top 16px
  width 100%

.search-box-input input
  border none
  outline none
  flex 1
  font-size 15px
  border 1px solid #E3E3E3
  padding 12px 36px 12px 16px
  width 100%

.search-input::placeholder
  color #999
  font-size 15px
  white-space nowrap
  overflow hidden
  text-overflow ellipsis
  pointer-events none
  max-width 100%

.search-result
  margin 36px 0

.del-button
  position absolute
  top 16px
  right 44px
  cursor pointer

.search-button
  position absolute
  top 14px
  right 16px
  background none
  border none
  cursor pointer

.survey-inquiry-container
  display flex
  justify-content center
  align-items center
  margin 120px 0

.survey-inquiry-wrapper
  display flex
  gap 24px
  width 1152px

.item-survey
.item-inquiry
  border-radius 8px
  flex 1
  height 100%
  display flex
  flex-direction column
  align-items center
  justify-content center
  text-align center
  padding 32px

.item-survey
  background-color $gray3
.item-inquiry
  background-color $subcolor50

.button-black
.button-sub2
  padding 10px 24px
  border-radius 50px
  font-size 15px
  font-weight 700
  color white

.button-black
  background-color #000
.button-sub2
  background-color $subcolor2




@media (max-width: 425px)
  .search-result
  .back-wrapper
    display none
  .template-search-container
    margin-top 59px
    padding 0 0 60px
  .template-search-header
    width 100%
    margin 0
    padding 0 16px
  .search-box-input
    margin-bottom 24px
  .search-box-input input
    height 44px
  .survey-inquiry-container
    padding 0 16px
  .survey-inquiry-wrapper
    display grid
    grid-template-columns repeat(1, 1fr)
    gap 24px
    width 100%

  .survey-inquiry-container
    margin 60px 0
</style>
